<template>
  <div>
    <p class="clearfix mb-0 ml-2 mr-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <!-- <a
          href="https://landing.salpalaran.com/"
          target="_blank"
          rel="noopener noreferrer"
          style="z-index: 999"
        >
          PT Sarana Abadi Lestari
        </a> -->
      </span>
      <span class="float-md-right d-none d-md-block"
        >Buku Tamu
        <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
        />
      </span>
    </p>
    <!-- search input -->
    <!-- http://localhost:3000/dir_visitor_qrcode/logo.png -->
    <!-- backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` -->

    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/sal.jpg')})`
        }"
      >
        <b-card-body class="card-body h-full">
          <b-card
            class="text-center"
            style="
              background: rgba(255, 255, 255, 0.2);
              backdrop-filter: blur(10px);
              -webkit-backdrop-filter: blur(10px);
              border-radius: 10px;
              color: #ffffff;
            "
          >
            <h1 class="text-white" style="font-weight: 900; font-size: 3em">
              Your security is our top priority.
            </h1>
            <b-card-text class="mb-2">
              <span>Last Entering Area: </span>
              <strong
                ><span class="font-weight-bolder"
                  >Welcome Handoko ...</span
                ></strong
              >
            </b-card-text>
          </b-card>

          <div class="mb-4"></div>
          <!-- form -->
          <!-- <b-form @submit.prevent class="kb-search-input">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="CpuIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                ref="searchInput"
                v-model="qrcode_scanning"
                @keypress.enter="processQRCode()"
                placeholder="  QRCode Scanning . . .  "
              />
            </b-input-group>
          </b-form> -->
          <div class="mb-4"></div>

          <b-img
            src="https://api.salpalaran.com/dir_visitor_qrcode/logo.png"
            alt="logo"
            :style="'width: 9em; height: 9em; object-fit: contain; border-radius: 50%; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border: 2px solid #fff; background-color: #f8f9fa; padding: 5px;'"
          />

          <b-img
            src="https://api.salpalaran.com/QRCode/logo.png"
            alt="logo"
            class="ml-2"
            :style="'width: 9em; height: 9em; object-fit: contain; border-radius: 50%; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); border: 4px solid #000; background-color: #fff; padding: 5px;'"
          />

          <b-img
            src="https://api.salpalaran.com/dir_visitor_qrcode/logo.png"
            alt="logo"
            class="ml-2"
            :style="'width: 9em; height: 9em; object-fit: contain; border-radius: 50%; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border: 2px solid #fff; background-color: #f8f9fa; padding: 5px;'"
          />

          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <p class="clearfix mb-0 ml-2 mr-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <small>
          © 2024 - {{ new Date().getFullYear() }}
          <a
            href="https://github.com/yogithesymbian"
            target="_blank"
            rel="noopener noreferrer"
            style="z-index: 999"
          >
            Yogi Arif Widodo
          </a>
          🎸
          <a
            href="https://landing.salpalaran.com/"
            target="_blank"
            rel="noopener noreferrer"
            style="z-index: 999"
          >
            PT Sarana Abadi Lestari
          </a>
        </small>
      </span>
      <span class="float-md-right d-none d-md-block"
        >Visitor
        <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
        />
      </span>
    </p>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BImg
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput
  },
  data() {
    return {
      scanBuffer: '' // Temporary storage for barcode scanner input
    }
  },
  mounted() {
    document.addEventListener('keypress', this.handleKeyPress)
  },

  beforeDestroy() {
    // Remove event listener to avoid memory leaks
    document.removeEventListener('keypress', this.handleKeyPress)
  },

  methods: {
    handleKeyPress(event) {
      if (event.key === 'Enter') {
        this.processQRCode(this.scanBuffer)
        this.scanBuffer = '' // Reset buffer after processing
      } else {
        // Add each keypress to the buffer (scanner sends characters as keystrokes)
        this.scanBuffer += event.key
      }
    },

    async executeQR(encrypted_key) {
      /* hit x2 api
      1. cek qr
      2. action in or out */
      await useJwt.http
        .post('visitor/scanning/get', {
          encrypted_key: encrypted_key
        })
        .then(async (result) => {
          const visitor_qrcode = result.data.visitor_qrcode
          const visitor_public = result.data.visitor_public
          const visitor_private = result.data.visitor_private

          if (visitor_qrcode.status.toLowerCase() === 'expired') {
            const message =
              'QRCode Sudah expired, Lakukan ReuseQR Terlebih dahulu untuk memasuki area PT SAL'
            this.$swal({
              title: 'EXPIRED',
              html: `${full_name} ${message}`,
              timer: 2000,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          } else {
            let _userData = null
            if (visitor_public) {
              _userData = visitor_public
            }

            if (visitor_private) {
              _userData = visitor_private
            }
            let payload = {
              visitor_qrcode_id: visitor_qrcode.id,
              full_name: _userData.full_name,
              badge: _userData.badge_id,
              qrcode_link: visitor_qrcode.qrcode_link,
              sys_auto_renew: visitor_qrcode.sys_auto_renew
            }
            await useJwt.http
              .post('visitor/scanning', payload)
              .then(async (result) => {
                console.log(result.data)
                const {
                  action_success,
                  action,
                  full_name,
                  badge,
                  action_message
                } = result.data
                if (action_success === true) {
                  if (action === 'clock_in') {
                    this.$swal({
                      title: 'Clock IN Area',
                      html: `${full_name} Berhasil Memasuki Area PT SAL`,
                      timer: 1000,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary'
                      },
                      buttonsStyling: false
                    })
                  } else {
                    this.$swal({
                      title: 'Clock OUT Area',
                      html: `${full_name} Berhasil Keluar Area PT SAL`,
                      timer: 1000,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary'
                      },
                      buttonsStyling: false
                    })
                  }
                } else {
                  this.$swal({
                    title: 'OOPS',
                    html: `${full_name} ${action_message}`,
                    timer: 2000,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                  })
                }
              })
          }
        })
        .catch((errors) => {
          this.$alert({
            title: `${full_name} Tidak berhasil Memasuki Area PT SAL ${errors}`,
            variant: 'danger',
            icon: 'CheckIcon'
          })
        })
    },
    async processQRCode() {
      await executeQR(this.scanBuffer)
      // Clear buffer after processing
      this.scanBuffer = ''
    },

    forceFocus() {
      const searchInput = this.$refs.searchInput.$el.querySelector('input')
      if (searchInput) {
        searchInput.focus()
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
